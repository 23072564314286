import { template as template_585d7859f3054c4fa4d6c103c26dc21c } from "@ember/template-compiler";
const FKLabel = template_585d7859f3054c4fa4d6c103c26dc21c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
